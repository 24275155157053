<template>
  <div class="about">
      <Top-banner :img='img' :title='title' :text='text'></Top-banner> 
      <div class='container1080-about'>
        <div class='mainTitle'>公司简介</div>
        <img class='companyImg' src="../static/images/company.jpg" alt="">
        <div class=companyText>杭州躺赢文化信息技术有限公司，是一家专业的跨境数字营销服务商。公司以躺赢文化海外流量推广运营社区（成立于2018年）为根基，一直专业致力于海外广告投放流量获取业务，专注效果投放，追求ROI最大化。帮助企业降低单次获客成本（CAC），提升客户终身价值（LTV）。   躺赢文化服务过生活用品、家居服饰、医疗保健、教育培训、快销、IT电子、机械化工、餐饮、商务服务、旅游、金融、家具家装、婚纱摄影、APP、游戏等众多行业。服务涉及数据调研分析、投放咨询、账户管理、营销策略策划等，帮助各企业投放总计超过1亿美元的广告，积累了丰富的广告咨询和策划经验。</div>
      </div>
  <!-- 专业互联网营销知识的人才 -->
        <div class='blockContainer'>
          <div class='container1080-about'>
            <div class='textBlock'>
              <div class='title'>专业互联网营销知识的人才</div>
              <div class='text'>
                躺赢文化团队拥有着一批高素质的，具有专业互联网营销知识的人才。其中各广告账户投放经理经验丰富，有过大量活跃平台管理的经历。爱竞科技坚持以“合作、共赢、整合、利他、互助、抱团、联盟”为经营理念，立志将公司打造成军队般的执行力，学校般的学习力，家庭般的幸福工作环境。
              </div>
              <div class='btnNeed'  @click='showModel'>我要投广告</div>
            </div>
            <img src="../static/images/block1.png" class='imgBlock' alt="">
          </div>
        </div>
        <!-- 官方资格合作伙伴认证 -->
        <div class='container1080-about'>
          <div class='blockContainer' style='background-color:#fff'>
            <img src="../static/images/block2.jpg" class='imgBlock' style='width:30%;float:left' alt="">
            <div class='textBlock' style='float:right'>
              <div class='title'>官方资格合作伙伴认证</div>
              <div class='text'>
                躺赢文化始终坚持着用数据和结果说话，在搜索和社交领域获得了众多全球巨头们的官方资格合作伙伴认证。其中有国际搜索引擎（Google、Bing、Yahoo、Yandex），国际社交媒体（Facebook、Twitter、Tiktok、VK），国内搜索引擎（百度、、今日头条、搜狗、广点通），国内社交媒体（新浪微博、腾讯、陌陌），视频媒体（Youtube、优酷、抖音）。
              </div>
              <div class='btnNeed'  @click='showModel'>我要投广告</div>
            </div>
          </div>
        </div>
        <!-- 开户咨询 -->
        <div style='background-color: #f9fcff;padding:40px 0'>
          <div class='container1080-about'>
            <div class='mainTitle'>开户咨询</div>
            <div>
              <div class='firstPhone'>
                <img style='display:inline-block' src="../static/images/touxiang.png" alt="">
                <div  style='display:inline-block'>
                  <p class='name'>Patrick</p>
                  <p class='phoneNumber'>手机：13777596016</p>
                  <p class='phoneNumber'>QQ:1651384167</p>
                </div>
                
              </div>
              <div class='firstPhone'>
                <img src="../static/images/touxiang.png" alt="">
                <div  style='display:inline-block'>
                  <p class='name'>ChengHongWei</p>
                  <p class='phoneNumber'>手机：15715715456</p>
                  <p class='phoneNumber'>QQ:1059793191</p>
                </div>  
              </div> 
            </div>
          </div>
        </div>
        <!-- 联系地址 -->
         <div class='container1080-about'>
          <div class='addressContainer'>
            <img src="../static/images/map.png" class='addressImg' alt="">
            <div class='addressBlock'>
              <p class='addressTitle'>联系地址</p>
              <p class='addressText'>公司电话：0571-61997217</p>
              <p class='addressText'>Email：jw@shuijingtong.com</p>
              <p class='addressText'>公司地址：北京市顺义区北小营镇小胡营村8号院3幢1层112室</p>
            </div>
          </div>
          <!-- 广告 -->
          <div class='advertising'>
              <p>专业广告投放助力您轻松获得全球客户，快速拓展海外目标市场</p>
              <div class='btnNeed' style='float:right'  @click='showModel'>我要投广告</div>
          </div>
         <my-model ref='myModel'></my-model>

        </div>
       
  </div>
</template>

<script>
import topBanner from '../components/topBanner';
import myModel from '../components/MyModel';


export default {
 name: 'About',
 components:{
   TopBanner:topBanner,
      MyModel:myModel
 },
  data() {
    return {
     img:require('../static/images/aboutBanner.jpg'),
     title:'专注效果投放，追求ROI最大化',
     text:'帮助企业降低单次获客成本（CAC），提升客户终身价值（LTV）'
    };
  },
     methods:{
    showModel(){
      this.$refs.myModel.visible = true;
    }
  }
};
</script>

<style>
 .container1080-about{
  width: 1080px;
  margin: auto;
}
 .mainTitle{ 
    font-size: 30px;
    color: #222;
    line-height: 46px;
    text-align: center;
    font-weight: bold;
    margin:30px 0;  
  }
  .mainTitle:after{
    display: block;
    content: "";
    width: 35px;
    height: 4px;
    margin: 16px auto 12px;
    border-radius: 2px;
    background-image: linear-gradient(90deg,#4bf5ff,#2f6aff);
  }
  .companyImg{
    width: 95%;
  }
  .companyText{
    width: 92%;
    line-height: 20px;
    font-size: 12px;
    color: #181818;
    letter-spacing: 1px;
    text-align: left;
    margin: 40px auto;
  }
  .blockContainer{
    padding: 40px 40px;
    background: #f9fcff;
    height: 420px;
  }
  .textBlock{
    text-align: left;
    width: 45%;
    float:left;

  }
  .textBlock .title{
    color: #333;
    font-size: 32px;
    text-align: left;
    margin-top: 30px;
  }
  .textBlock .text{
    margin: 20px 0;
    color: #9c9c9c;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
  }
.btnNeed{
  padding: 10px 35px;
    border: none;
    font-size: 14px;
    line-height: 24px;
    outline: none;
    margin-top: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    color: #fff;
    border-radius: 28px;
    display: inline-block;
    background: linear-gradient(90deg,#43c7ff,#3878ff) #3878ff;
}
.imgBlock{
  width: 40%;
}
.firstPhone{
  width: 50%;
  display: inline-block;
}
.firstPhone img{
width: 100px;
margin-right: 20px;
margin-bottom: 50px;
}
.name{
      margin-bottom: 6px;
    color: #181818;
    font-size: 16px;
}
.phoneNumber{
  color: #6b7799;
    font-size: 15px;

}
.addressContainer{
  padding:50px 40px;
}
.addressImg{
  width: 45%;
  display: inline-block;
  margin-right:15%;
  vertical-align: top !important;
}
.addressBlock{
  width:40%;
  text-align: left;
  display: inline-block;
}
.addressTitle{
    color: rgb(24, 24, 24);
    font-size: 18px;
    padding-bottom: 20px;
    font-weight: bold;
}
.addressText{
line-height: 50px;
    color: #545454;
    opacity: .9;
    font-size: 14px;
}
.advertising{
    width: 95%;
    margin: auto;
    margin-top: 80px;
    height: 150px;
    background: url('../static/images/adversiting.jpg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 12px;
    padding: 0 50px;
    position: relative;
    left: 8px;
    box-shadow: 17px 23px 21px 2px hsla(0,13%,89%,.5);
}
.advertising p{
    color: #fdfbfb;
    text-align: left;
    font-size: 20px;
    width: 475px;
    position: absolute;
    top: 45px;
}
</style>